"use client";

import { WeatherIcon } from "@components/images";
import { useUserSettings } from "@hooks";
import { IWeatherResponse } from "@types";
import { parseTemperature } from "@utils/parse";
import { isNumber } from "lodash";
import { useTranslation } from "next-i18next";

interface Props {
  data: IWeatherResponse["current_weather"];
}

export const WeatherWidget = ({ data }: Props) => {
  const { t } = useTranslation();
  const { temperature } = useUserSettings();

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center justify-between gap-2 md:gap-4">
        <div className="flex flex-col items-end">
          <p className="text-lg font-semibold tracking-tight md:text-3xl">
            {parseTemperature(data.temperature, temperature)}
          </p>
          <p className="hidden text-sm leading-none md:flex">
            {t(`weather.${data.weathercode}`)}
          </p>
        </div>

        {isNumber(data?.weathercode) && (
          <WeatherIcon code={data.weathercode} isDay={Boolean(data.is_day)} />
        )}
      </div>
    </div>
  );
};
