import { Card } from "@components/ui";
import { IconStar } from "@tabler/icons-react";
import { ICity } from "@types";
import { getCityMainImage } from "@utils/images";
import { getCityLink } from "@utils/routes";
import Link from "next/link";

interface Props {
  data: ICity;
  isFavorite?: boolean;
}

export const CityTile = ({ data, isFavorite }: Props) => (
  <Link href={getCityLink(data._id)}>
    <div className="duration-200 hover:scale-95">
      <Card
        className="relative w-full h-24 px-4 py-2 overflow-hidden text-gray-100 bg-transparent bg-center bg-no-repeat bg-cover bg-clip-padding"
        bgImage={getCityMainImage(data?.image, 200)}
      >
        <div className="flex flex-col items-start justify-between h-full">
          <div className="self-end">
            {isFavorite && <IconStar fill="white" size={18} />}
          </div>

          <p className="w-full text-lg font-semibold leading-tight tracking-tight line-clamp-2">
            {data.name}
          </p>
        </div>
        <div className="absolute w-full h-full top-0 left-0 -z-10 rounded-md bg-gradient-to-b from-50% from-transparent to-black" />
      </Card>
    </div>
  </Link>
);
