import { isNumber } from "lodash";
import Image, { ImageProps } from "next/image";

type Props = {
  code?: number;
  isDay?: boolean;
  size?: number;
} & Omit<ImageProps, "src" | "alt">;

export const WeatherIcon = ({
  code,
  isDay = true,
  size = 46,
  ...rest
}: Props) => {
  if (!isNumber(code)) return null;

  return (
    <Image
      height={size}
      width={size}
      {...rest}
      src={`/images/weather/${code}${isDay ? "" : "n"}.png`}
      alt="weather condition"
    />
  );
};
