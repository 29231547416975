import { cn } from "@/lib/utils";
import { FavoriteCityButton } from "@components/city";
import { CountryIcon } from "@components/country";
import { Badge, Card, Skeleton, Tooltip } from "@components/ui";
import apiRoutes from "@constants/api-routes";
import { valuesCityTags } from "@constants/form-values";
import { usePostRequest } from "@hooks";
import { useAppStore } from "@store";
import { ICity, IFavorite } from "@types";
import { getApiUrl } from "@utils/api";
import { getCityMainImage } from "@utils/images";
import { getCountryLink } from "@utils/routes";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useMemo } from "react";
import { CompareCityButton } from "./compare-button";
import { MoreOptionsCityButton } from "./more-options-button";
import { StatRating } from "./stat-rating";

interface Props {
  data?: ICity;
  isLoading?: boolean;
}

export const CityHeroCard = ({ data, isLoading }: Props) => {
  if (isLoading) return <Skeleton className="flex flex-1 w-full h-72" />;

  return (
    <>
      <Card
        className="grid flex-1 grid-cols-2 gap-4 p-4 text-gray-100 bg-center bg-no-repeat bg-cover bg-clip-padding md:px-8 md:py-4 h-72 min-h-72 bg-black/60 lg:px-10 lg:py-6"
        bgImage={getCityMainImage(data?.image, 960)}
      >
        {/* Top left corner */}
        <div className="flex items-start justify-start">
          <div className="hidden w-full lg:flex">
            <PartialTags data={data} />
          </div>
        </div>

        {/* Top right corner */}
        <div className="flex items-start justify-end">
          <PartialActions data={data} />
        </div>

        {/* Bottom left corner */}
        <div className="flex items-end justify-start col-span-2 md:col-span-1">
          <PartialCityName data={data} />
        </div>

        {/* Bottom right corner */}
        <div className="items-end justify-end hidden md:flex">
          <PartialScores data={data} />
        </div>
      </Card>

      {/* Bottom right corner mobile  */}
      <div className="flex md:hidden">
        <PartialMobileScores data={data} />
      </div>
    </>
  );
};

const PartialTags = ({ data }: { data?: ICity }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <div className="flex flex-wrap items-start gap-2">
      {data?.tags?.map((item) => {
        const value = valuesCityTags.find((x) => x.value === item);
        if (!value) return null;

        return (
          <Badge
            key={item}
            className="gap-2 font-medium tracking-tight"
            variant="secondary"
          >
            {value.image && (
              <Image src={value.image} alt="tag image" height={16} width={16} />
            )}
            {t(value?.label)}
          </Badge>
        );
      })}
    </div>
  );
};

const PartialActions = ({ data }: { data?: ICity }) => {
  const store = useAppStore();
  const request = usePostRequest<IFavorite>(
    getApiUrl(apiRoutes.cityFav, data?._id)
  );
  const isFavorited = useMemo(() => {
    if (!data?._id) return false;
    return store.favCities.includes(data._id);
  }, [data?._id, store.favCities]);

  const onToggleFavorite = useCallback(async () => {
    if (!data?._id) return false;
    try {
      await request.trigger();
      store.toggleFavCity(data?._id);
    } catch (error) {
      console.error(error);
    }
  }, [request, store, data?._id]);

  if (!data) return null;

  return (
    <div className="flex justify-end gap-2">
      <CompareCityButton cityId={data._id} />
      <FavoriteCityButton
        isSelected={isFavorited}
        onClick={onToggleFavorite}
        isLoading={request.isLoading}
      />
      <MoreOptionsCityButton data={data} />
    </div>
  );
};

const PartialCityName = ({ data }: { data?: ICity }) => {
  if (!data) return null;

  return (
    <div className="flex flex-col">
      <div className="flex gap-1">
        <Link href={getCountryLink(data?.countryCode ?? "")}>
          <p className="text-sm">{data?.country}</p>
        </Link>
      </div>

      <div className="flex items-center gap-2">
        <CountryIcon code={data.countryCode} className="h-7 w-7" />
        <p className="text-4xl font-bold tracking-tight truncate">
          {data.name}
        </p>
      </div>
    </div>
  );
};

const PartialScores = ({ data }: { data?: ICity }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <div className="flex items-center justify-end w-full">
      <div className="flex justify-center gap-8">
        <CityHeroWidget
          value={data?.costOfLife}
          label={t("stats.costOfLife")}
          tooltip={t("stats.costOfLifeDescription")}
        />

        <CityHeroWidget
          value={data?.qualityOfLife}
          label={t("stats.qualityOfLife")}
          tooltip={t("pages.city.qualityOfLifeDescription")}
        />
      </div>
    </div>
  );
};

const PartialMobileScores = ({ data }: { data?: ICity }) => {
  const { t } = useTranslation();

  if (!data) return null;

  return (
    <div className="flex justify-around w-full gap-4">
      <CityHeroWidget
        value={data?.costOfLife}
        label={t("stats.costOfLife")}
        tooltip={t("stats.costOfLifeDescription")}
        className="justify-center w-1/2 border rounded-md shadow border-border h-28 bg-card"
      />
      <CityHeroWidget
        value={data?.qualityOfLife}
        label={t("stats.qualityOfLife")}
        tooltip={t("stats.qualityOfLifeDescription")}
        className="justify-center w-1/2 border rounded-md shadow border-border h-28 bg-card"
      />
    </div>
  );
};

interface CityHeroWidgetProps {
  label: string;
  value?: string | number;
  tooltip?: string;
  className?: string;
}

const CityHeroWidget = ({
  label,
  value,
  tooltip,
  className,
}: CityHeroWidgetProps) => {
  if (!value) return null;

  return (
    <Tooltip tooltip={tooltip} side="bottom" asChild>
      <div className={cn("flex flex-col items-center gap-1", className)}>
        <StatRating score={value} className="text-[44px] leading-none" />
        <p className="text-[10px] font-bold text-center uppercase leading-tight">
          {label}
        </p>
      </div>
    </Tooltip>
  );
};
