import { cn } from "@/lib/utils";
import { useTranslation } from "next-i18next";

interface Props {
  score?: number | string;
  className?: string;
}

export const StatRating = ({ score, className }: Props) => {
  if (!score) return <PartialNoData />;

  return (
    <div className="flex items-baseline gap-[0.5]">
      <p className={cn("text-4xl font-bold leading-none", className)}>
        {Number(score)}
      </p>
      <p className="font-semibold text-muted-foreground">/10</p>
    </div>
  );
};

const PartialNoData = () => {
  const { t } = useTranslation();

  return (
    <p className="text-sm uppercase text-muted-foreground">
      {t("common.noData")}
    </p>
  );
};
