"use client";

import { cn } from "@/lib/utils";
import { DataTileWithIcon } from "@components/cards";
import { GradeTextColored } from "@components/common";
import { useUserSettings } from "@hooks/use-user-settings";
import {
  IconCloudRain,
  IconSun,
  IconTemperature,
  IconWind,
} from "@tabler/icons-react";
import { ICity } from "@types";
import {
  parseAirQualityToGrade,
  parseRainfallToGrade,
  parseSunlightHoursToGrade,
  parseTemperature,
} from "@utils/parse";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMemo } from "react";

interface Props {
  data: ICity;
  className?: string;
}
export const CityWeatherTilesSection = ({ data, className }: Props) => {
  const { t } = useTranslation();
  const { temperature } = useUserSettings();
  const router = useRouter();

  const temperatureCurrentMonth = useMemo(() => {
    const currentMonth = new Date()
      .toLocaleString("en-US", { month: "short" })
      .toLowerCase();

    return data.avgTemperatureByMonth?.[currentMonth] ?? null;
  }, []);

  return (
    <div
      className={cn(
        "grid h-full grid-cols-2 gap-4 sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2",
        className
      )}
    >
      {data?.sunlightHours && (
        <div className="lg:w-[12.5rem]">
          <DataTileWithIcon
            title={t("stats.sunlightHours")}
            subtitle={
              <GradeTextColored
                value={Number(parseSunlightHoursToGrade(data.sunlightHours))}
              />
            }
            description={t("common.hoursPerYear")}
            value={data.sunlightHours}
            Icon={IconSun}
          />
        </div>
      )}
      {data?.rainfall && (
        <div className="lg:w-[12.5rem]">
          <DataTileWithIcon
            title={t("stats.rainfall")}
            subtitle={
              <GradeTextColored
                value={Number(parseRainfallToGrade(data.rainfall))}
              />
            }
            description={t("common.mmPerYear")}
            value={data.rainfall}
            Icon={IconCloudRain}
          />
        </div>
      )}
      {data?.airQuality && (
        <div className="lg:w-[12.5rem]">
          <DataTileWithIcon
            title={t("stats.airQuality")}
            subtitle={
              <GradeTextColored
                value={Number(parseAirQualityToGrade(data.airQuality))}
              />
            }
            description={t("common.aqi")}
            value={data.airQuality}
            Icon={IconWind}
          />
        </div>
      )}
      {temperatureCurrentMonth && (
        <div className="lg:w-[12.5rem]">
          <DataTileWithIcon
            title={t("stats.avgTemperature")}
            subtitle={
              <span>
                {t("common.inMonth", {
                  month: new Date().toLocaleString(router.locale, {
                    month: "long",
                  }),
                })}
              </span>
            }
            description={t("common.estimation")}
            value={parseTemperature(temperatureCurrentMonth, temperature)}
            Icon={IconTemperature}
          />
        </div>
      )}
    </div>
  );
};
