"use client";

import { useModal } from "@components/modals";
import { Button, Tooltip } from "@components/ui";
import { useMeUser } from "@services";
import { IconSettings } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";

interface Props {
  isExtended?: boolean;
}

export const CurrentCitySwitcher = ({ isExtended }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const profile = useMeUser();

  const onCloseModal = () => {
    profile.mutate();
  };

  const handleOpen = () => {
    openModal("currentCity", { data: profile.data, onCloseModal });
  };

  return (
    <div className="flex flex-col items-end">
      <Tooltip
        tooltip={isExtended ? undefined : t("pages.home.changeCurrentCity")}
        asChild
      >
        {isExtended ? (
          <Button variant="outline" onClick={handleOpen}>
            <IconSettings size={18} />
            {t("pages.home.changeCurrentCity")}
          </Button>
        ) : (
          <Button
            aria-label={t("pages.home.changeCurrentCity")}
            variant="ghost"
            size="icon"
            onClick={handleOpen}
          >
            <IconSettings size={18} />
          </Button>
        )}
      </Tooltip>
    </div>
  );
};
