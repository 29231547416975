"use client";

import { cn } from "@/lib/utils";
import { ProLabel } from "@components/pro";
import {
  Alert,
  Badge,
  Button,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
  Textarea,
} from "@components/ui";
import { valuesCityAdvisorMovingOptions } from "@constants/form-values";
import proItems from "@constants/pro-items";
import prompts from "@constants/prompts";
import { usePayment } from "@hooks";
import { fetchCityData, useCityAdvice } from "@services/city";
import {
  IconArrowLeft,
  IconLoader2,
  IconPlus,
  IconSend,
  IconSparkles,
  IconX,
} from "@tabler/icons-react";
import { AdvisorStayLength, ISelectOption } from "@types";
import { motion } from "framer-motion";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useCallback, useEffect, useState } from "react";

const proItem = proItems.cityStayLength;

export const CityAdvisor = () => {
  const { t } = useTranslation();
  const { query } = useRouter();
  const { advice, generate, clear, isGenerating } = useCityAdvice();
  const [selectedTag, setSelectedTag] = useState<ISelectOption>();
  const [customIntent, setCustomIntent] = useState("");
  const { makePayment } = usePayment();

  const onSubmit = async () => {
    try {
      await makePayment(proItem.cost);
      const lengthOfStay = selectedTag
        ? prompts.advisor[selectedTag.value as AdvisorStayLength]
        : "";
      await onProvideResponse(lengthOfStay, customIntent);
    } catch (error) {
      const { message } = error as Error;
      console.error(message);
    }
  };

  const onProvideResponse = useCallback(
    async (lengthOfStay: string, customIntent: string) => {
      const city = await fetchCityData(String(query.id));
      const destination = `${city.name}, ${city.country}`;
      generate({ destination, lengthOfStay, customIntent });
    },
    [query.id]
  );

  useEffect(() => {
    clear();
    setCustomIntent("");
  }, [query.id]);

  return (
    <Card
      className="w-full h-full overflow-auto bg-bottom bg-no-repeat bg-cover text-neutral-100 bg-clip-padding bg-black/70"
      bgImage="/backgrounds/midnight-river.webp"
    >
      <CardHeader className="lg:max-h-[5.5rem] max-w-3xl mx-auto">
        <div className="flex justify-between w-full gap-2">
          <div className="flex items-center gap-2">
            <IconSparkles size={32} />
            <div className="flex flex-col items-start">
              <CardTitle className="text-xl">
                {t("pages.city.advisor.title")}
              </CardTitle>
              <CardDescription className="text-neutral-100/70">
                {t("pages.city.advisor.subtitle")}
              </CardDescription>
            </div>
          </div>
          <ProLabel tokens={proItem.cost} />
        </div>
      </CardHeader>
      <CardContent className="flex max-w-3xl mx-auto lg:max-h-[17.5rem]">
        {advice ? (
          <div className="flex flex-col w-full">
            <div className="flex justify-between w-full">
              <div className="flex flex-col">
                <div className="flex items-baseline gap-1">
                  <p className="text-4xl font-bold tracking-tight">
                    {advice.score}
                  </p>
                  <p className="font-semibold text-neutral-100/70">/10</p>
                </div>
                <p className="text-sm font-bold uppercase text-neutral-100/70">
                  {t("pages.city.advisor.ourRecommendation")}
                </p>
              </div>
              <Button
                variant="outline"
                className="text-foreground hover:bg-background/80"
                size="sm"
                onClick={clear}
                disabled={isGenerating}
              >
                <IconArrowLeft size={18} />
                {t("actions.back")}
              </Button>
            </div>
            <div className="flex flex-col gap-3 px-2 overflow-auto">
              <p>{advice.description}</p>
              {advice.concerns && (
                <Alert className="flex flex-col items-start gap-2 my-1">
                  <p className="mt-1 text-sm font-bold leading-none uppercase">
                    {t("pages.city.advisor.concernsTitle")}
                  </p>
                  <p>{advice.concerns}</p>
                </Alert>
              )}
            </div>
          </div>
        ) : isGenerating ? (
          <div className="flex items-center justify-center w-full my-4">
            <IconLoader2 className="animate-spin" size={32} />
          </div>
        ) : (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.2 }}
            animate={{ opacity: 1, y: 0 }}
            className="w-full"
          >
            <div className="flex flex-col h-full gap-4 py-2 lg:gap-4">
              {/* Select stay options */}
              <div className="flex flex-col h-full gap-2">
                {valuesCityAdvisorMovingOptions.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => setSelectedTag(item)}
                    className="flex flex-col items-start md:flex-row group gap-1 md:gap-4 bg-neutral-700/30 max-w-[30rem] px-4 py-2 rounded-md cursor-pointer"
                  >
                    <Badge
                      className={cn(
                        "flex items-center gap-1 lg:group-hover:outline-primary outline-2 outline outline-neutral-600",
                        selectedTag?.value === item.value && "outline-primary"
                      )}
                      variant="secondary"
                      size="lg"
                    >
                      <IconPlus size={16} />
                      <p className="font-medium leading-none">
                        {t(item.label)}
                      </p>
                    </Badge>
                    <p>{t(item.description)}</p>
                  </div>
                ))}
              </div>

              {/* Custom intent */}
              <div className="relative items-start md:items-end flex flex-col md:flex-row gap-2 max-w-[36rem]">
                {selectedTag && (
                  <div className="flex h-full md:absolute">
                    <Badge
                      className="flex items-center justify-center w-full h-6 rounded-lg cursor-pointer min-w-[5.5rem] md:mx-3 md:my-2 group group-hover:outline-primary outline-2 outline outline-neutral-600"
                      variant="secondary"
                      onClick={() => setSelectedTag(undefined)}
                    >
                      <span className="truncate group-hover:hidden">
                        {t(selectedTag.label)}
                      </span>
                      <span className="hidden group-hover:flex">
                        <IconX size={12} />
                      </span>
                    </Badge>
                  </div>
                )}
                <Textarea
                  className={cn(
                    "flex-1 w-full resize-none lg:w-[24rem] h-12 border-neutral-800 bg-neutral-800/60 focus:bg-neutral-900/80 focus:ring-none",
                    selectedTag && "md:ps-28"
                  )}
                  value={customIntent}
                  onChange={(e) => setCustomIntent(e.target.value)}
                  placeholder={t(
                    selectedTag
                      ? "pages.city.advisor.addIntentPlaceholder"
                      : "pages.city.advisor.customIntentPlaceholder"
                  )}
                />
                <Button
                  aria-label="send"
                  className="self-end w-auto px-4 py-2 md:p-0 md:h-12 md:w-12"
                  size="icon"
                  variant="primary"
                  onClick={onSubmit}
                  disabled={!customIntent && !selectedTag}
                >
                  <span className="text-lg md:hidden">{t("actions.send")}</span>
                  <IconSend size={24} />
                </Button>
              </div>
            </div>
          </motion.div>
        )}
      </CardContent>
    </Card>
  );
};
