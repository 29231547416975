import { useModal } from "@components/modals";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/ui";
import { useAuth } from "@hooks";
import { IconDots, IconPencil } from "@tabler/icons-react";
import { ICity } from "@types";
import { useTranslation } from "next-i18next";

interface Props {
  data: ICity;
}

export const MoreOptionsCityButton = ({ data }: Props) => {
  const { t } = useTranslation();
  const { openModal } = useModal();
  const { isAdmin } = useAuth();

  if (!isAdmin) return null;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          size="icon"
          className="h-8 text-gray-100 min-w-8 bg-gray-300/20 active:bg-gray-300/40 hover:bg-gray-300/40"
        >
          <IconDots size={18} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem onClick={() => openModal("editCity", { data })}>
          <IconPencil size={18} />
          <p className="truncate">{t("pages.admin.editCity")}</p>
        </DropdownMenuItem>

        <DropdownMenuItem
          onClick={() => openModal("editAdvancedStats", { data: data._id })}
        >
          <IconPencil size={18} />
          <p className="truncate">{t("pages.admin.editAdvStats")}</p>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
