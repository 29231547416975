"use client";

import { Section } from "@components/common";
import { Accordion, Skeleton } from "@components/ui";
import appConfig from "@constants/app-config";
import { useAdvancedStats } from "@services";
import { IAdvancedStats, ISectionProps } from "@types";
import { useTranslation } from "next-i18next";
import { CityAdvancedStatsItem } from "./advanced-stats-item";

interface Props {
  cityId: string;
  isEmptyHidden?: boolean;
  SectionProps?: Partial<ISectionProps>;
}

export const CityAdvancedStatsSection = ({
  cityId,
  isEmptyHidden,
  SectionProps,
}: Props) => {
  const { t } = useTranslation();
  const { data, isLoading, error } = useAdvancedStats(cityId, {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <Section
      title={t("pages.city.advancedStatistics")}
      isLoading={isLoading}
      isEmpty={Boolean(error)}
      skeleton={<SkeletonLoader />}
      {...SectionProps}
    >
      {data && (
        <Accordion type="multiple" className="w-full">
          <div className="grid-dynamic-relaxed">
            {appConfig.cities.advancedStatsOrder.map((label, index) => {
              const item = data[label as keyof IAdvancedStats];
              if (!item && isEmptyHidden) return;

              return (
                <CityAdvancedStatsItem
                  key={index}
                  value={`item-${index}`}
                  item={{ ...item, label }}
                />
              );
            })}
          </div>
        </Accordion>
      )}
    </Section>
  );
};

const SkeletonLoader = () => (
  <div className="grid-dynamic-relaxed">
    {Array.from({ length: 12 }, (_, index) => (
      <Skeleton key={index} className="h-20 mb-3" />
    ))}
  </div>
);
