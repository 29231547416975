"use client";

import { Badge, Tooltip } from "@components/ui";
import { IconCoins } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";

interface Props {
  tokens?: number;
}

export const ProLabel = ({ tokens }: Props) => {
  const { t } = useTranslation();

  return (
    <Tooltip tooltip={t("pages.profile.tokensProFeature")}>
      <Badge className="flex gap-2 font-bold uppercase cursor-default hover:bg-unset">
        <div className="flex items-center gap-1">
          <p>{tokens}</p>
          <IconCoins size={15} />
        </div>
        Pro
      </Badge>
    </Tooltip>
  );
};
