import { CountryIcon } from "@components/country";
import { Button, Card } from "@components/ui";
import { IconArrowRight, IconUsers } from "@tabler/icons-react";
import { ICity } from "@types";
import { getCityMainImage } from "@utils/images";
import { parseDistance } from "@utils/parse";
import { getCityLink } from "@utils/routes";
import { useTranslation } from "next-i18next";
import Link from "next/link";

interface Props {
  data: ICity;
  isCostOfLifeDisabled?: boolean;
  isDistanceDisabled?: boolean;
  isQualityOfLifeDisabled?: boolean;
  isUserCountDisabled?: boolean;
}

export const CityPlacard = ({ data, ...options }: Props) => {
  const { t } = useTranslation();

  return (
    <Link href={getCityLink(data._id)}>
      <Card className="h-[14rem] p-0 overflow-hidden group">
        <Card
          className="flex flex-col relative justify-between w-full h-[14rem] py-4 px-5 overflow-hidden text-gray-100 duration-200 bg-center bg-no-repeat bg-cover border-0 md:group-hover:h-[10rem] bg-black/30 bg-clip-padding"
          bgImage={getCityMainImage(data?.image, 460)}
        >
          <div className="flex justify-end w-full gap-3">
            {data?.qualityOfLife && !options?.isQualityOfLifeDisabled && (
              <div className="flex flex-col items-center gap-1">
                <p className="text-[9px] font-medium leading-none tracking-tight text-center uppercase max-w-20">
                  {t("stats.qualityOfLife")}
                </p>
                <div className="flex items-baseline font-medium leading-none">
                  <p>{data.qualityOfLife}</p>
                  <p className="text-xs">/10</p>
                </div>
              </div>
            )}
            {data?.costOfLife && !options?.isCostOfLifeDisabled && (
              <div className="flex flex-col items-center gap-1">
                <p className="text-[9px] font-medium leading-none tracking-tight text-center uppercase max-w-20">
                  {t("stats.costOfLife")}
                </p>
                <div className="flex items-baseline font-medium leading-none">
                  <p>{data.costOfLife}</p>
                  <p className="text-xs">/10</p>
                </div>
              </div>
            )}
            {data?.userCount && !options?.isUserCountDisabled && (
              <div className="flex gap-1 font-bold">
                <IconUsers size={18} />
                <p>{data.userCount}</p>
              </div>
            )}
          </div>

          <div className="flex items-end justify-between w-full">
            <div className="flex flex-col">
              <p className="text-xs leading-none truncate">
                {t(`continents.${data.continent}`)}
              </p>
              <div className="flex items-center gap-2">
                <CountryIcon code={data.countryCode} className="w-5 h-5" />
                <p className="text-2xl font-semibold leading-tight tracking-tight truncate">
                  {data.name}
                </p>
              </div>
            </div>

            {Boolean(data?.distance && !options?.isDistanceDisabled) && (
              <div className="flex flex-col items-center gap-1">
                <p className="text-[9px] font-medium leading-none tracking-tight text-center uppercase max-w-20">
                  {t("common.distance")}
                </p>
                <div className="flex items-baseline text-sm leading-none">
                  <p>{parseDistance(data.distance)}</p>
                </div>
              </div>
            )}
          </div>
          <div className="absolute w-full h-full top-0 left-0 -z-10 rounded-md bg-gradient-to-b from-70% from-transparent to-black" />
        </Card>

        {/* Lower part shown on hover */}
        <div className="items-center justify-center h-[4rem] hidden w-full gap-2 px-5 duration-500 ease-in-out md:group-hover:flex">
          <Button
            variant="link"
            className="text-xl font-bold hover:no-underline"
          >
            {t("common.details")}
            <IconArrowRight size={18} />
          </Button>
        </div>
      </Card>
    </Link>
  );
};
