const prompts = {
  advancedStats: {
    foodPrices:
      "Estimate the food prices of {{CITY_NAME}}. The output should be in JSON format containing two keys: 'score' and 'description'. The 'score' property should be a grade between 1 and 10 without decimals where 1 is very cheap and 10 is very expensive, taking into account all the cities worldwide. The 'description' should be a text between 240 and 400 characters, at least 5 sentences long, explaining food prices in restaurants, groceries, local markets, and so on. If the 'description' field exceeds 400 characters, please summarize the content so it stays below 400 characters. Do not return anything except the JSON output. This is an example output for one city: {\"foodPrices\":{\"score\":9,\"description\":\"In Oslo, Norway, food prices are considerably high, ranking among the top cities globally. Restaurants, both upscale and casual, tend to be pricey. Groceries and products from local markets also come at a premium, reflecting the country's high living standards and import costs. Tourists and residents alike often note the significant expenditure on food.\"}}",
    weather:
      "Estimate the weather and climate of {{CITY_NAME}}. The output should be in JSON format containing two keys: 'score' and 'description'. The 'score' property should be a grade between 1 and 10 without decimals where 1 is very unpleasant weather and 10 is very pleasant, taking into account all the cities worldwide. The 'description' should be a text between 240 and 400 characters, at least 5 sentences long, explaining rain frecuency, snow, sun hours, daylight length, humidity, wind and climate in general. If the 'description' field exceeds 400 characters, please summarize the content so it stays below 400 characters. Do not return anything except the JSON output. This is an example output for one city: {\"weather\":{\"score\":6,\"description\":\"Climate in Zurich is temperate with four distinct seasons. Rainfall is evenly distributed throughout the year. Snow can be expected during winter months. Summers are warm with ample sunshine, while winters are cold and days are shorter. Wind conditions are generally calm, and humidity levels are moderate.\"}}",
    nature:
      "Estimate the nature and environment of {{CITY_NAME}}. The output should be in JSON format containing two keys: 'score' and 'description'. The 'score' property should be a grade between 1 and 10 without decimals where 1 is very bad and 10 is very good, taking into account all the cities worldwide. The 'description' should be a text between 240 and 400 characters, at least 5 sentences long, explaining nature, neighboring places, and options available to explore in general. If the 'description' field exceeds 400 characters, please summarize the content so it stays below 400 characters. Do not return anything except the JSON output. This is an example output for one city: {\"nature\":{\"score\":8,\"description\":\"Zurich, Switzerland boasts a harmonious blend of urban and natural beauty. Nestled by the pristine Lake Zurich, it's surrounded by snow-capped Alps that beckon nature enthusiasts. The city offers verdant parks like Uetliberg, providing panoramic views of both the city and the Alps. Nearby, the Zurich Forest is a haven for hikers and wildlife lovers. From cultural excursions in the Old Town to idyllic boat trips on the lake, Zurich provides a myriad of exploratory options.\"}}",
    language:
      "Estimate the diversity of language and fluency of {{CITY_NAME}}. The output should be in JSON format containing two keys: 'score' and 'description'. The 'score' property should be a grade between 1 and 10 without decimals where 1 is very hard to communicate and 10 is very easy, taking into account all the cities worldwide. The 'description' should be a text between 240 and 400 characters, at least 5 sentences long, explaining the languages spoken, the proficiency in English and how easy it is to communicate in general. If the 'description' field exceeds 400 characters, please summarize the content so it stays below 400 characters. Do not return anything except the JSON output. This is an example output for one city: {\"language\":{\"score\":8,\"description\":\"Zurich blends urban charm with natural beauty. Lying beside Lake Zurich, it offers tranquil waterside views. The Alps provide a striking backdrop, alluring nature lovers. Uetliberg Mountain offers panoramic sights, while nearby trails invite exploration. Zurich's old town adds a touch of cultural richness, making it a diverse destination for all.\"}}",
    openness:
      "Estimate the cultural openness of {{CITY_NAME}}. The output should be in JSON format containing two keys: 'score' and 'description'. The 'score' property should be a grade between 1 and 10 without decimals where 1 is not so culturally open and 10 is very culturally open, taking into account all the cities worldwide. The 'description' should be a text between 240 and 400 characters, at least 5 sentences long, explaining how tolerant, how acceptant the city is towards other cultures in the world, and how culturally open and diverse in general. Please avoid being politically correct, be strict, serious and rigid. If the 'description' field exceeds 400 characters, please summarize the content so it stays below 400 characters. Do not return anything except the JSON output. This is an example output for one city: {\"openness\": {\"score\":4,\"description\":\"Byalystok, situated in Poland's northeast, once thrived as a multicultural hub, influenced by Jewish, Belarusian, and Lithuanian communities. Today, its dominant culture is overwhelmingly Polish, with only faint remnants of its once-diverse heritage. The city's residents largely adhere to traditional Polish values, exhibiting limited openness to foreign cultures. While there are efforts to remember its multicultural past, Byalystok's present-day cultural landscape is more insular than inclusive.\"}}",
    safety:
      "Estimate the safety perception of {{CITY_NAME}}. The output should be in JSON format containing two keys: 'score' and 'description'. The 'score' property should be a grade between 1 and 10 without decimals where 1 is not so safe and 10 is extremely safe, taking into account all the cities worldwide. The 'description' should be a text between 240 and 400 characters, at least 5 sentences long, explaining how safe the city feels, considering all factors such as crime rates, physical environment and social environment, for all kinds of people including women, the elderly, and people of color. Please avoid being politically correct, be strict, serious and rigid. If the 'description' field exceeds 400 characters, please summarize the content so it stays below 400 characters. Do not return anything except the JSON output. This is an example output for one city: {\"safety\": {\"score\":7,\"description\":\"New York, while iconic, presents a mixed safety profile. As one of the world's major metropolises, it has neighborhoods that are both safe and treacherous. Crime rates, especially in certain areas, are higher than some global cities. While many residents and tourists feel secure, disparities persist for vulnerable groups like women, the elderly, and people of color in some districts. Vigilance and awareness are paramount when navigating the city.\"}}",
    accessibility:
      "Estimate the accessibility ease of {{CITY_NAME}} by considering factors such as transportation infrastructure, public transit availability, pedestrian-friendly amenities, and the inclusivity of the city's design. Provide a comprehensive evaluation that takes into account both physical and digital accessibility, and consider the needs of diverse populations, including individuals with disabilities, elderly residents, and tourists. Additionally, analyze the city's efforts in promoting sustainable and eco-friendly transportation options. Finally, rate the city's overall accessibility compared to other cities worldwide on a scale of 1 to 10, with 1 being extremely challenging and 10 being exceptionally easy, providing justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict and give extreme scores if neccessary. Never exceed 400 characters. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"accessibility\": {\"score\": 2, \"description\": Lagos grapples with severe accessibility issues. Overburdened infrastructure and erratic public transit hinder mobility. Pedestrian spaces are scarce and often unsafe. Digital access is minimal, excluding many. Despite some efforts, sustainable transit solutions are embryonic, placing Lagos near the bottom in global accessibility rankings.\"}}",
    housingAccess:
      "Estimate the housing accessibility of {{CITY_NAME}} by considering factors such as affordability, availability of housing units, inclusivity in housing policies, and the overall ease of finding suitable housing options. Provide a comprehensive evaluation that takes into account the needs of diverse populations, including individuals with disabilities, low-income residents, and newcomers. Additionally, assess the city's efforts in addressing homelessness and providing housing assistance programs. Finally, rate the city's overall housing accessibility on a scale of 1 to 10, with 1 being extremely inaccessible and 10 being highly accessible, providing justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict, and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"housingAccess\": {\"score\": 7, \"description\": \"Oslo, Norway demonstrates relatively high housing accessibility. Affordability remains a challenge, but the availability of housing units is better than in many cities. Inclusivity in housing policies is a priority, accommodating individuals with disabilities and newcomers. The city has made commendable efforts to address homelessness and provide housing assistance programs. Overall, Oslo rates a 7, signifying a reasonably accessible housing market. However, affordability remains a concern for some residents, preventing a perfect score.\"}}",
    sports:
      "Estimate the sports and activities offerings of {{CITY_NAME}} by considering factors such as the variety of sports facilities, availability of public parks and recreational areas, and the frequency of sports events and tournaments. Provide a comprehensive evaluation that takes into account both indoor and outdoor activities, and consider the needs of diverse populations, including children, adults, elderly residents, and tourists. Additionally, analyze the city's efforts in promoting health and wellness through active lifestyles and community engagements. Finally, rate the city's overall sports and activities offerings compared to other cities worldwide on a scale of 1 to 10, with 1 being somehow limited and 10 being very rich. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"sports\": {\"score\": 9, \"description\": \"London presents an extensive array of sports facilities and events, catering to all ages and preferences. Public parks are abundant, and there's a strong emphasis on promoting active lifestyles. The city regularly hosts major international tournaments, making it a global hub for sports. However, with its vast population, facilities can sometimes be crowded.\"}}",
    nightlife:
      "Estimate the nightlife and festival offerings of {{CITY_NAME}} by considering factors such as the diversity of nighttime venues, frequency and variety of festivals, safety during nighttime, and the cultural significance of events. Provide a comprehensive evaluation that takes into account both popular tourist spots and local favorites, and consider the needs of diverse populations, including young adults, tourists, and cultural enthusiasts. Additionally, analyze the city's efforts in promoting local cultures and traditions. Finally, rate the city's overall nightlife and festival scene compared to other cities worldwide on a scale of 1 to 10, with 1 being very lacking and 10 being very vibrant. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict, and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"nightlife\": {\"score\": 9, \"description\": \"London boasts a rich nightlife with diverse venues catering to various tastes. Festivals, both modern and traditional, are frequent, reflecting the city's cosmopolitan nature. Safety measures during events are commendable. While popular tourist hubs are lively, local pubs and festivals offer genuine British experiences. It stands as a global leader in nightlife and cultural events.\"}}",
    housingCost:
      "Estimate the cost of housing of {{CITY_NAME}} by considering factors such as the average rent for apartments, price of residential properties, associated taxes, and cost-related amenities such as utilities and maintenance. Provide a comprehensive evaluation that takes into account both rental and purchase markets, and consider the housing needs of diverse populations, including students, working professionals, families, and retirees. Additionally, analyze the city's efforts in providing affordable housing options and schemes for its residents. Finally, rate the city's overall housing cost compared to other cities worldwide on a scale of 1 to 10, with 1 being extremely affordable and 10 being extremely expensive. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"housingCost\": {\"score\": 9, \"description\": \"London's housing market is among the priciest globally. Average rents and property prices are significantly high, burdening both locals and expats. Taxes and utilities further escalate costs. While efforts exist to provide affordable housing, they're insufficient for the demand. Compared to other cities, London remains notably unaffordable.\"}}",
    investmentEase:
      "Estimate the ease of investment in {{CITY_NAME}} by considering factors such as the city's business-friendly regulations, availability of skilled labor, real estate affordability, and the transparency of the city's financial institutions. Provide a comprehensive evaluation that considers both domestic and foreign investments, and take into account the needs of diverse business sectors, including startups, established corporations, and individual investors. Additionally, analyze the city's efforts in promoting sustainable business practices and incentives for green investments. Finally, rate the city's overall ease of investment compared to other cities worldwide on a scale of 1 to 10, with 1 being very challenging and 10 being very inviting. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict, and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"investmentEase\": {\"score\": 9, \"description\": \"Zurich, a global financial hub, is highly conducive for investment. It offers clear, business-friendly regulations and a pool of highly skilled labor. Though real estate can be pricey, the city's financial institutions are among the world's most transparent. Zurich also prioritizes sustainable business, offering incentives for green initiatives. Compared to global cities, it's a standout for investment ease.\"}}",
    bureaucracy:
      "Estimate the financial bureaucracy of {{CITY_NAME}} by considering factors such as the efficiency of tax systems, availability of financial services, and transparency of financial regulations. Provide a comprehensive evaluation that contemplates both traditional and digital financial operations, and consider the needs of diverse populations, including local entrepreneurs, foreign investors, and general residents. Additionally, analyze the city's efforts in promoting financial literacy and its integration with global financial systems. Finally, rate the city's overall financial bureaucracy compared to other cities worldwide on a scale of 1 to 10, with 1 being very bureaucratic and cumbersome and 10 being very streamlined. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"bureaucracy\": {\"score\": 7, \"description\": \"Paris, as a major global city, has a sophisticated financial system. Tax structures, while intricate, are efficient with a digitalized process. The city boasts a robust offering of financial services, catering to both locals and foreigners. Regulatory transparency is high, although navigating it requires knowledge. Efforts to promote financial literacy are commendable, integrating well with global standards.\"}}",
    business:
      "Estimate the ease to do business in {{CITY_NAME}} by considering factors such as the simplicity of starting a business, regulatory environment, efficiency of obtaining permits, and clarity of business-related laws. Provide a comprehensive evaluation that encompasses both the traditional and digital business environments, and consider the needs of diverse business types, including startups, established companies, foreign entities, and freelancers. Additionally, assess the city's initiatives in fostering a business-friendly climate, its support systems for entrepreneurs, and the adaptability of its economic policies. Finally, rate the city's overall ease to do business compared to other cities worldwide on a scale of 1 to 10, with 1 being very difficult and 10 being very straightforward. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"business\": {\"score\": 8, \"description\": \"London offers a favorable business climate. Starting a business is generally straightforward, with clear regulatory guidelines. While obtaining permits can be time-consuming, the process is transparent. Digital business infrastructures are advanced, catering to diverse entities. The city proactively fosters entrepreneurial growth, making it a prime hub for global business endeavors.\"}}",
    salaries:
      "Estimate the level of salaries and wages in {{CITY_NAME}} by considering factors such as average income across various sectors, minimum wage laws, income equality, and the cost of living. Provide a comprehensive evaluation that takes into account the financial wellbeing of diverse populations, including entry-level workers, experienced professionals, and industry-specific employees. Additionally, analyze the city's efforts in promoting fair compensation, wage growth, and economic opportunities. Finally, rate the city's overall salary and wage standards compared to other cities worldwide on a scale of 1 to 10, with 1 being low and 10 being very high. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Please avoid being politically correct, be rigid, strict and never exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"salaries\": {\"score\": 10, \"description\": \"Zurich offers some of the highest salaries globally, reflecting its robust economy and high living costs. No minimum wage law, but high average incomes prevail. Sectors like finance and tech pay particularly well. Income disparity exists but is moderated by social policies. The city's efforts in ensuring competitive wages and promoting economic opportunities are substantial, aligning with its high cost of living.\"}}",
    friendliness:
      "Estimate the community friendliness of {{CITY_NAME}} by considering factors such as the sense of community among residents, availability and participation in community events, the presence of support networks, and the inclusivity of different cultures and backgrounds. Provide a comprehensive evaluation that reflects on both social cohesion and community engagement, considering the experiences of diverse groups including new residents, minorities, and long-standing inhabitants. Additionally, assess the city's efforts in fostering a welcoming atmosphere and encouraging volunteerism and civic participation. Finally, rate the city's overall community friendliness compared to other cities worldwide on a scale of 1 to 10, with 1 being very unfriendly or isolated and 10 being very welcoming and inclusive. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be clear, forthright, and strict without exceeding 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"friendliness\": {\"score\": 8, \"description\": \"Madrid scores high in community friendliness. The city is known for its warm, welcoming atmosphere, with active participation in community events. Support networks for various groups are robust. Madrid's inclusivity of different cultures enhances its social cohesion. Efforts to foster volunteerism and civic engagement are visible, making it a favorable environment for both new and long-standing residents.\"}}",
    feedback:
      "Assess the community feedback of {{CITY_NAME}} by analyzing residents' reviews and perspectives, especially those who have recently moved there. Consider factors such as the overall satisfaction with city services, quality of life, community engagement, and the balance of pros and cons as highlighted by the inhabitants. Provide a comprehensive evaluation that reflects the real-life experiences and opinions of a diverse range of residents, including families, professionals, students, and retirees. Additionally, explore the common themes in feedback regarding the city's living conditions, amenities, safety, and social environment. Finally, rate the city's overall community feedback compared to other cities worldwide on a scale of 1 to 10, with 1 being negative feedback and 10 being very positive. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be candid, stringent, and do not exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"feedback\": {\"score\": 7, \"description\": \"Dubai's residents generally express satisfaction, especially with the city's modern amenities, safety, and cleanliness. The diverse expat community enjoys the cosmopolitan lifestyle. However, the high cost of living and summer heat are drawbacks. Community engagement varies, with some feeling isolated. Overall, Dubai offers a unique blend of luxury and efficiency but with notable challenges.\"}}",
    foodQuality:
      "Estimate the quality and variety of food options in {{CITY_NAME}} by considering factors such as the diversity of cuisines available, the presence of high-quality restaurants, accessibility to fresh and healthy food options, and the prevalence of local and international food markets. Provide a comprehensive evaluation that takes into account the culinary experiences of diverse populations, including locals, food enthusiasts, tourists, and individuals with dietary restrictions. Additionally, analyze the city's efforts in promoting food sustainability and supporting local food producers. Finally, rate the city's overall food quality and types compared to other cities worldwide on a scale of 1 to 10, with 1 being poor and limited and 10 being very diverse and high-quality. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be objective, exacting, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"foodQuality\": {\"score\": 10, \"description\": \"Paris excels in culinary diversity and quality. Renowned for its high-quality restaurants and iconic French cuisine, it also offers a wide array of international options. Accessibility to fresh, healthy foods is exemplary, with numerous local markets. The city actively supports food sustainability and local producers. For varied diets and food enthusiasts, Paris is a global leader in gastronomy.\"}}",
    ecoFriendliness:
      "Estimate the eco-friendliness of {{CITY_NAME}} by considering factors such as the effectiveness of waste management and recycling programs, the availability and quality of green spaces, the city's policies and practices on renewable energy usage, and the promotion of sustainable living among residents. Provide a comprehensive evaluation that takes into account environmental initiatives at both the governmental and community levels, and consider the impact on diverse populations, including residents, businesses, and visitors. Additionally, analyze the city's efforts in reducing carbon footprint and preserving natural resources. Finally, rate the city's overall eco-friendliness compared to other cities worldwide on a scale of 1 to 10, with 1 being very lacking in eco-friendly practices and 10 being a leader in environmental sustainability. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be forthright, stringent, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"ecoFriendliness\": {\"score\": 7, \"description\": \"Madrid makes considerable efforts in eco-friendliness. The city has effective waste management and recycling programs, though there's room for improvement. It boasts numerous green spaces, enhancing urban living. Renewable energy usage is growing, aligning with sustainable policies. Efforts in promoting eco-conscious living among residents are visible, with initiatives to reduce carbon footprint and preserve natural resources.\"}}",
    veganFriendliness:
      "Estimate the vegan friendliness of {{CITY_NAME}} by considering factors such as the availability and variety of vegan dining options, the presence of vegan-friendly grocery stores, the general awareness and acceptance of veganism among the population, and the availability of plant-based alternatives in mainstream establishments. Provide a comprehensive evaluation that takes into account the ease and convenience for vegans living in or visiting the city, including considerations for diverse groups such as residents, tourists, and individuals with health or ethical motivations. Additionally, analyze the city's efforts in promoting plant-based lifestyles and supporting sustainable food practices. Finally, rate the city's overall vegan friendliness compared to other cities worldwide on a scale of 1 to 10, with 1 being very challenging for vegans and 10 being exceptionally accommodating. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be unambiguous, rigorous, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"veganFriendliness\": {\"score\": 9, \"description\": \"Stockholm is highly accommodating for vegans, boasting a wide range of vegan dining options. Vegan-friendly grocery stores are prevalent, and plant-based alternatives are commonly found in mainstream establishments. The general population shows a growing awareness and acceptance of veganism. The city actively promotes plant-based lifestyles and sustainable food practices, making it an ideal destination for vegans.\"}}",
    transportAccess:
      "Estimate the transport accessibility of {{CITY_NAME}} by considering factors such as the quality and extent of transportation infrastructure, availability of diverse transit options including public transport, ease of pedestrian and cycling movements, and the city's efforts in integrating technology for transport efficiency. Provide a comprehensive evaluation that takes into account the connectivity and convenience for diverse groups, including individuals with disabilities, elderly residents, commuters, and tourists. Additionally, analyze the city's initiatives in promoting sustainable and eco-friendly transport solutions. Finally, rate the city's overall transport accessibility compared to other cities worldwide on a scale of 1 to 10, with 1 being very inaccessible and 10 being very accessible. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be objective, stringent, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"transportAccess\": {\"score\": 9, \"description\": \"Stockholm excels in transport accessibility with a well-developed, extensive public transport system, including buses, trains, and ferries. The city is highly walkable and bike-friendly, featuring numerous cycling paths. There's a strong focus on accessibility for all, including disabled and elderly individuals. Technology integration for transport efficiency is advanced. Sustainable transport is a key focus, aligning with the city's eco-friendly initiatives.\"}}",
    transportCost:
      "Estimate the transport cost of {{CITY_NAME}} by considering factors such as the average fares for public transportation (buses, trains, etc.), the cost of taxi and ride-sharing services, parking fees, and the expense of maintaining a private vehicle in the city. Provide a comprehensive evaluation that takes into account how these costs impact different segments of the population, including low-income residents, daily commuters, and occasional travelers. Also, examine any city-led initiatives to subsidize or reduce transport costs and how these efforts compare to other cities. Finally, rate the city's overall transport cost compared to other cities worldwide on a scale of 1 to 10, with 1 being very affordable transportation and 10 being high transport expenses. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be objective, stringent, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"transportCost\": {\"score\": 7, \"description\": \"Transport costs in Oslo are moderate. Public transportation fares are reasonable but can accumulate for daily commuters. Taxis and ride-sharing are pricier, impacting low-income residents. Parking fees and private vehicle maintenance are high due to efforts to promote eco-friendly transit. City initiatives offer some subsidies, but overall transport expenses remain significant compared to other cities, balancing between cost and sustainability.\"}}",
    opportunities:
      "Estimate the access to opportunities in {{CITY_NAME}} by considering factors such as the availability of job prospects across various industries, the quality and accessibility of educational institutions, the presence of cultural and recreational amenities, and the support for entrepreneurship and innovation. Provide a comprehensive evaluation that takes into account the prospects for diverse populations, including recent graduates, professionals, artists, and entrepreneurs. Additionally, analyze the city's efforts in creating an inclusive environment that promotes social mobility and equal opportunity. Finally, rate the city's overall access to opportunities compared to other cities worldwide on a scale of 1 to 10, with 1 being extremely limited and 10 being exceptionally abundant. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be objective, stringent, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"opportunities\": {\"score\": 4, \"description\": \"Lagos offers mixed opportunities. It's a hub for entrepreneurship and has a dynamic informal sector, but formal job prospects are uneven across industries. Educational institutions vary in quality and accessibility. Cultural amenities are present but not widespread. Efforts for inclusivity and social mobility are ongoing, yet challenges in infrastructure and social disparity impact the overall access to opportunities.\"}}",
    touristFriendliness:
      "Estimate the tourist friendliness of {{CITY_NAME}} by considering factors such as the availability and quality of tourist information, ease of navigation for visitors, the variety and accessibility of attractions, and the overall attitude of locals towards tourists. Provide a comprehensive evaluation that accounts for the experiences of diverse tourists, including those with disabilities, families, solo travelers, and international visitors. Additionally, analyze the city's efforts in promoting tourism-friendly policies, such as multilingual support, tourist safety measures, and initiatives to showcase local culture and heritage. Finally, rate the city's overall tourist friendliness compared to other cities worldwide on a scale of 1 to 10, with 1 being unwelcoming or difficult for tourists and 10 being very hospitable and easy to navigate. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be candid, critical, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"touristFriendliness\": {\"score\": 9, \"description\": \"London excels in tourist friendliness. The city offers extensive tourist information, easily accessible attractions, and is generally easy to navigate. Locals are typically welcoming to visitors. Efforts in multilingual support and safety are notable. London's rich culture and heritage are prominently showcased, enhancing the tourist experience. Accessibility for diverse tourist needs is well-addressed, making it a top global destination.\"}}",
    travelAccessibility:
      "Estimate the travel accessibility of {{CITY_NAME}} by considering factors such as the connectivity of the city to national and international destinations, the efficiency and frequency of public transit to and from major travel hubs like airports and train stations, the availability of travel information and support services, and the ease of navigation for newcomers. Provide a comprehensive evaluation that takes into account the experiences of diverse groups, including business travelers and tourists. Finally, rate the city's overall travel accessibility compared to other cities worldwide on a scale of 1 to 10, with 1 being very challenging and 10 being very convenient. Provide justification for the assigned rating in the 'description' property between 240 and 400 characters long and at least 5 sentences long. Be candid, stringent, and ensure not to exceed 400 characters. Don't hesitate to give extreme scores if necessary. The output should be in JSON format containing only two keys: 'score' and 'description'. Do not return anything except the JSON output. This is an example output for one city: {\"travelAccessibility\": {\"score\": 9, \"description\": \"Belgrade's travel accessibility is moderate. The city offers decent connectivity to national and international destinations, though not as extensive as larger European capitals. Public transit to major travel hubs like airports and train stations is adequate but could be more frequent and efficient. Navigation for newcomers is manageable, and there are efforts to improve travel information and support services.\"}}",
  },
  advisor: {
    shortLength: "visiting for less than a month for tourism",
    mediumLength: "moving to live there for some months",
    longLength: "relocating for a long period of time",
  },
};

export default prompts;
