"use client";

import { DataPillWithIcon, StatPillWithIcon } from "@components/cards";
import { GradeTextColored } from "@components/common";
import { Badge } from "@components/ui";
import { useUserSettings } from "@hooks";
import { useAdvancedStats } from "@services";
import {
  IconArrowUp,
  IconLanguage,
  IconMoneybag,
  IconNetwork,
  IconUserPin,
  IconUsers,
} from "@tabler/icons-react";
import { ICity } from "@types";
import { getHighestAdvancedStats } from "@utils/city";
import {
  parseLanguageName,
  parseMoneyAmount,
  parsePercentNumber,
  parsePopulationNumber,
} from "@utils/parse";
import { useTranslation } from "next-i18next";

export const CityDataPillsSection = ({ data }: { data: ICity }) => {
  const { t } = useTranslation();
  const { exchangeRate } = useUserSettings();
  const { data: advStats } = useAdvancedStats(data._id);
  const topStats = advStats && getHighestAdvancedStats(advStats, 4);

  return (
    <div className="grid w-full gap-4 md:grid-cols-2">
      <div className="grid w-full col-span-2 gap-4 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3">
        <DataPillWithIcon
          title={t("stats.population")}
          value={parsePopulationNumber(data?.population, true)}
          Icon={IconUsers}
        />
        <DataPillWithIcon
          title={t("stats.avgSalary")}
          value={parseMoneyAmount(data?.avgSalary, exchangeRate)}
          Icon={IconMoneybag}
        />
        {data?.internetSpeed && (
          <DataPillWithIcon title={t("stats.internetSpeed")} Icon={IconNetwork}>
            <div className="flex items-baseline gap-2">
              <p className="text-3xl font-bold truncate">
                {data.internetSpeed}
              </p>
              <span>mbps</span>
            </div>
          </DataPillWithIcon>
        )}
      </div>
      <div className="flex flex-col w-full col-span-2 gap-4 md:flex-row lg:flex-col xl:flex-row">
        <DataPillWithIcon
          title={t("pages.city.languageDistribution")}
          Icon={IconLanguage}
          className="h-full md:w-2/3 lg:w-full xl:w-3/4"
        >
          <div className="flex flex-wrap gap-2">
            {data.languageDistribution?.map((item) =>
              Object.entries(item).map(([key, value], index) => (
                <Badge key={index} className="gap-2" variant="outline">
                  <span>{parseLanguageName(key)}</span>
                  <span>{parsePercentNumber(value, 0)}</span>
                </Badge>
              ))
            )}
          </div>
        </DataPillWithIcon>
        <DataPillWithIcon
          title={t("pages.city.usersLivingHere")}
          value={String(data.usersLivingHere?.length)}
          Icon={IconUserPin}
          className="h-full md:w-1/3 lg:w-full xl:w-1/4"
        />
      </div>
      <div className="grid w-full col-span-2 gap-4 md:grid-cols-2">
        {topStats?.map((item, index) => (
          <StatPillWithIcon
            key={index}
            title={t(`pages.city.stats.${item.name}`)}
            description={<GradeTextColored value={item.score} />}
            grade={item.score}
            Icon={IconArrowUp}
          />
        ))}
      </div>
    </div>
  );
};
