import { CityTile } from "@components/city";
import { Section } from "@components/common";
import { Skeleton } from "@components/ui";
import { useCities } from "@services";
import { useAppStore } from "@store";
import { ISectionProps } from "@types";

interface Props {
  countryCode: string;
  currentCityId?: string;
  itemsLimit?: number;
  SectionProps?: Partial<ISectionProps>;
}

export const CountryCitiesSection = ({
  countryCode,
  currentCityId,
  itemsLimit,
  SectionProps,
}: Props) => {
  const { data, isLoading } = useCities({
    countryCode,
    ...(itemsLimit && { limit: itemsLimit }),
    ...(currentCityId && { excludeId: currentCityId }),
    sort: "popularity",
    sortOrder: "desc",
  });
  const { favCities } = useAppStore();

  return (
    <Section
      isLoading={isLoading}
      isEmpty={!isLoading && !data?.length}
      skeleton={<SkeletonLoader />}
      {...SectionProps}
    >
      <div className="grid-dynamic-tighter">
        {data?.map((item, index) => (
          <CityTile
            key={index}
            data={item}
            isFavorite={favCities.includes(item._id)}
          />
        ))}
      </div>
    </Section>
  );
};

const SkeletonLoader = () => (
  <div className="grid-dynamic-tight">
    {Array.from({ length: 12 }).map((_, index) => (
      <Skeleton key={index} className="h-24" />
    ))}
  </div>
);
