"use client";

import { CountryIcon } from "@components/country";
import {
  Button,
  Command,
  CommandEmpty,
  CommandItem,
  CommandList,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@components/ui";
import routes from "@constants/routes";
import { useDebounce } from "@hooks";
import { fetchSearchData } from "@services/index";
import { useAppStore } from "@store";
import {
  IconAnalyze,
  IconChevronDown,
  IconCurrentLocation,
} from "@tabler/icons-react";
import { ISearchResult } from "@types";
import { getUrl } from "@utils/api";
import { parseHighlightSearchTerm } from "@utils/parse";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

interface Props {
  cityId: string;
}

export const CompareCityButton = ({ cityId }: Props) => {
  const { t } = useTranslation();
  const { user } = useAppStore();
  const [searchString, setSearchString] = useState("");
  const [searchResults, setSearchResults] = useState<ISearchResult[]>([]);
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const excludeId = user?.livingInId;
  const isNotUserCity = cityId !== excludeId;
  const debouncedSearchString = useDebounce(searchString, 500);

  // Search on type
  useEffect(() => {
    if (!debouncedSearchString) return;

    (async () => {
      const result = await fetchSearchData(debouncedSearchString, {
        noCountries: true,
      });
      setSearchResults(result);
    })();
  }, [debouncedSearchString]);

  const onLoseBlur = () => {
    inputRef.current?.blur();
    setSearchString("");
    setSearchResults([]);
  };

  const onSelectCity = (itemId: string) => {
    onLoseBlur();
    const link = getUrl(routes.compare, [cityId, itemId]);
    router.push(link);
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="ghost"
          className="h-8 text-gray-100 hover:text-current bg-gray-300/20 active:bg-gray-300/40 hover:bg-gray-300/40"
        >
          <IconAnalyze size={18} />
          {t("actions.compare")}
          <IconChevronDown className="ml-2" size={16} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {user?.livingIn && isNotUserCity && (
          <Link href={getUrl(routes.compare, [cityId, excludeId ?? ""])}>
            <DropdownMenuItem>
              <IconCurrentLocation size={18} />
              <p className="truncate">
                {t("pages.city.compareWith", { name: user.livingIn.name })}
              </p>
            </DropdownMenuItem>
          </Link>
        )}

        <Popover open={Boolean(searchString)}>
          <PopoverTrigger asChild>
            <div className="relative flex items-center">
              <Input
                ref={inputRef}
                className="w-full h-10 border-0 active:border-0 focus-visible:ring-0"
                placeholder={t("pages.city.compareWithOther")}
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
                onBlur={(e) => {
                  e.relatedTarget?.role?.includes("dialog")
                    ? inputRef.current?.focus()
                    : onLoseBlur();
                }}
              />
            </div>
          </PopoverTrigger>

          <PopoverContent className="w-[12rem] p-0" side="right" align="start">
            <Command>
              <CommandList>
                <CommandEmpty>{t("common.noOptionsFound")}</CommandEmpty>
                {searchResults.map((item, index) => (
                  <CommandItem
                    key={index}
                    onSelect={() => onSelectCity(item._id)}
                    className="px-4 cursor-pointer"
                  >
                    <CountryIcon code={item.countryCode} />
                    <div className="flex flex-col">
                      <p
                        className="w-full text-sm truncate"
                        dangerouslySetInnerHTML={{
                          __html: parseHighlightSearchTerm(
                            item.label,
                            debouncedSearchString
                          ),
                        }}
                      />
                    </div>
                  </CommandItem>
                ))}
              </CommandList>
            </Command>
          </PopoverContent>
        </Popover>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
