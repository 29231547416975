/**
 * Return placeholder image if not given
 * If it's an image from Unsplash change its size to a bigger value
 */
export const getCityMainImage = (
  image?: string,
  resizeSize: number | false = 1080
) => {
  if (!image) return "/backgrounds/landing.webp";
  if (!resizeSize || !image.includes("unsplash")) return image;
  const newUrl = new URL(image);
  newUrl.searchParams.set("w", String(resizeSize));
  return newUrl.toString();
};

export const getAdvancedStatImage = (key: string): string => {
  switch (key) {
    case "accessibility":
      return "/images/color/landing.webp";
    case "bureaucracy":
      return "/images/color/stamped.webp";
    case "business":
      return "/images/color/cooperation.webp";
    case "ecoFriendliness":
      return "/images/color/ecology.webp";
    case "feedback":
      return "/images/color/reviews.webp";
    case "foodPrices":
      return "/images/color/food-price.webp";
    case "foodQuality":
      return "/images/color/diet.webp";
    case "friendliness":
      return "/images/color/friends.webp";
    case "housingAccess":
      return "/images/color/house.webp";
    case "housingCost":
      return "/images/color/cost-housing.webp";
    case "investmentEase":
      return "/images/color/investment.webp";
    case "language":
      return "/images/color/languages.webp";
    case "nature":
      return "/images/color/forest.webp";
    case "nightlife":
      return "/images/color/music.webp";
    case "openness":
      return "/images/color/world.webp";
    case "opportunities":
      return "/images/color/job-seeker.webp";
    case "safety":
      return "/images/color/crime.webp";
    case "salaries":
      return "/images/color/budget.webp";
    case "sports":
      return "/images/color/sport.webp";
    case "touristFriendliness":
      return "/images/color/tourist.webp";
    case "transportAccess":
      return "/images/color/bus.webp";
    case "transportCost":
      return "/images/color/transport-cost.webp";
    case "travelAccessibility":
      return "/images/color/ticket.webp";
    case "veganFriendliness":
      return "/images/color/herb.webp";
    case "weather":
      return "/images/color/cloudy.webp";
    default:
      return "";
  }
};
