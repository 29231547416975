"use client";

import { cn } from "@/lib/utils";
import { Button } from "@components/ui";
import { useAuth } from "@hooks";
import { IconStar, IconStarOff } from "@tabler/icons-react";
import { useTranslation } from "next-i18next";

interface Props {
  isLoading: boolean;
  isSelected: boolean;
  onClick: () => void;
}

export const FavoriteCityButton = ({
  isLoading,
  isSelected,
  onClick,
}: Props) => {
  const { t } = useTranslation();
  const { isLogged } = useAuth();

  if (!isLogged) return null;

  return (
    <Button
      size="sm"
      isLoading={isLoading}
      onClick={onClick}
      className={cn(
        "text-base text-gray-100",
        isSelected
          ? "bg-primary/60 active:bg-primary/50 hover:bg-primary/50"
          : "bg-gray-300/20 active:bg-gray-300/40 hover:bg-gray-300/40"
      )}
    >
      {isSelected ? <IconStarOff size={18} /> : <IconStar size={18} />}
    </Button>
  );
};
