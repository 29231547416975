import { cn } from "@/lib/utils";
import { GradeTextColored } from "@components/common";
import {
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@components/ui";
import { IAdvancedStat, IAdvancedStats } from "@types";
import { getAdvStatsSortOrder } from "@utils/city";
import { getAdvancedStatImage } from "@utils/images";
import { parseGradeToColor } from "@utils/parse";
import { useTranslation } from "next-i18next";
import Image from "next/image";

interface ICityAdvancedStat extends IAdvancedStat {
  label: string;
  icon?: string;
}

interface Props {
  item: ICityAdvancedStat;
  value?: string;
}

export const CityAdvancedStatsItem = ({ item, value = "" }: Props) => {
  const { t } = useTranslation();

  return (
    <AccordionItem value={value}>
      <Card className="w-full overflow-hidden h-fit">
        <AccordionTrigger
          className={cn("w-full px-6", item.score && "cursor-pointer")}
        >
          <CardHeader className="p-0">
            <div className="flex w-full gap-4 truncate">
              <Image
                alt={t(`pages.city.stats.${item.label}`)}
                src={getAdvancedStatImage(item.label)}
                height={42}
                width={42}
              />

              <div className="flex flex-col gap-1">
                <CardTitle className="uppercase truncate">
                  {t(`pages.city.stats.${item.label}`)}
                </CardTitle>
                <CardDescription>
                  {item.score ? (
                    <PartialGrade item={item} />
                  ) : (
                    <p className="font-semibold text-start text-muted-foreground">
                      N/A
                    </p>
                  )}
                </CardDescription>
              </div>
            </div>
          </CardHeader>
        </AccordionTrigger>

        {item.description && (
          <AccordionContent className="pb-4 text-base">
            <CardContent>{item.description}</CardContent>
          </AccordionContent>
        )}
      </Card>
    </AccordionItem>
  );
};

const PartialGrade = ({ item }: Props) => {
  const sortOrder = getAdvStatsSortOrder(item.label as keyof IAdvancedStats);

  return (
    <div className="flex items-baseline gap-4">
      <div className="flex items-baseline gap-[0.5]">
        <p
          className={cn(
            "text-xl font-bold leading-none",
            parseGradeToColor(item.score, sortOrder)
          )}
        >
          {item.score}
        </p>
        <p className="font-semibold text-muted-foreground">/10</p>
      </div>
      <GradeTextColored
        className="font-normal"
        value={Number(item.score)}
        order={sortOrder}
      />
    </div>
  );
};
